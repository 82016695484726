

.menu {
  position: absolute;
  top: 0;
  right: 0;
}

.version {
  position: absolute;
  top: 0;
  left: 0;
  font-size: x-small;
  color: lightgray;
}

.lastfive, .hartfive {
  color: white;
  position: absolute;
  bottom: 0;
  width: 200px;
  max-width: 45vw;
}

.lastfive {
  right: 0;
}

.hartfive {
  left: 0;  
}

.logoContainer {  
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.logoMaxHeight {
  height: 50vh;
}

.logoMaxWidth {
  width: 50vw;
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: black;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}